<template>
    <div>
        <!--热点关键词Start-->
        <div class="widget-body">
            <div class="widget-tags">
                <ul>
                <li v-for="item in AllLables.slice(0,6)" :key="item">
                    <router-link :to="{path:'/ModsLabels',query: {name:item.labelsname}}">
                    <a href="" class="mr-0" style="padding-right:3px">{{item.labelsname}}</a>
                    </router-link>
                    </li>
                <!--
                <li><a class="tags-padding mr-0" href="job.html">Bird’s</a></li>
                <li><a class="tags-padding" href="job.html">Charity</a></li>
                <li><a class="mr-0" href="job.html">Forest</a></li>
                <li><a href="job.html">Water</a></li>
                <li><a class="tags-padding mr-0" href="job.html">Children</a></li>
                <li><a class="tags-padding" href="job.html">Human</a></li>
                <li><a href="job.html">Jungle</a></li>-->
                </ul>
            </div>
        </div>
        <!--热点关键词End-->
    </div>
</template>
<script>
import {getAllLables} from '../../../api/index'
export default {
    data(){
        return{
            AllLables:[]
        }
    },
   methods:{
       async getLables(){
          let res=await getAllLables()
          //console.log('全部标签：',res);
          this.AllLables=res
    },
   },
   created(){
      //1.获取标签
      this.getLables()
  },
}
</script>
