<template>
    <div>
        <!--热门分类组件Start-->
        <div class="widget-item">
            <div class="widget-body">
                <div class="widget-search-box">
                    <form action="#" method="post">
                        <div class="form-input-item">
                            <input type="search" id="search2" placeholder="请输入关键字" v-model="name">
                            <button type="submit" class="btn-src" @click="toByNameOrLabers">
                            <i class="icofont-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>  
        <!--热门分类组件End-->
    </div>
</template>
<script>
export default {
    data(){
        return{
            name:''
        }
    },
    methods:{
        toByNameOrLabers(){
            console.log('输入的：',this.name);
            this.$router.push({path: `/ModsBy`, query: {name:this.name}})
        }
    }
}
</script>