<template>
    <div>
        <!--热门分类组件Start-->
        <div class="widget-body">
            <div class="widget-categories">
                <ul>                    
                    <li><router-link :to="{path:'/ModsOf',query: {key:'word'}}">Word模板<span>({{WordsLength}})</span></router-link></li>
                    <li><router-link :to="{path:'/ModsOf',query: {key:'excel'}}">Excel模板<span>({{ExcelsLength}})</span></router-link></li>
                    <li><router-link :to="{path:'/ModsOf',query: {key:'ppt'}}">PPT模板<span>({{PptLength}})</span></router-link></li>
                    <li><router-link :to="{path:'/ModsOf',query: {key:'pic'}}">广告设计区<span>({{PicsLength}})</span></router-link></li>
                </ul>
            </div>
        </div>
        <!--热门分类组件End-->
    </div>
</template>
<script>
import {getAllWords,getAllExcel,getAllPpt,getAllPng} from '../../../api/index'
export default {
    data(){
        return{
            WordsLength:'',
            PptLength:'',
            ExcelsLength:'',
            PicsLength:''
        }
    },
    created(){
        this.getOfWord()
        this.getOfPpt()
        this.getOfExcel()
        this.getOfPic()

    },
    methods:{
        async getOfWord(){
            await getAllWords().then(res => {
                this.WordsLength=res.length
            })                       
        },
        async getOfPpt(){
            await getAllPpt().then(res => {
                this.PptLength=res.length
            })                       
        },
        async getOfExcel(){
            await getAllExcel().then(res => {
                this.ExcelsLength=res.length
                
            })                       
        },
        async getOfPic(){
            await getAllPng().then(res => {
                this.PicsLength=res.length
            })                       
        }
        
    }
}
</script>