<template>
    <div>
        <div class="wrapper">    
        <main class="main-content">
            <!--== Start==-->
            <section class="blog-area blog-grid-area">
            <div class="container">
                <div class="row justify-content-between flex-xl-row-reverse">
                    <div class="col-xl-8">
                        <div class="row row-gutter-70">
                            <div class="Bytitles">
                                <div class="BytitlesBackground">
                                    <i class="el-icon-s-opportunity"></i><span>为您找到“{{this.Byname}}”相关结果:{{ModsSelect.length}}个</span>
                                </div>
                            </div>
                            
                             <div v-if="ModsSelect.length==0">                    
                                <el-empty description="未查询到相关数据" image="https://iconfont.alicdn.com/p/illus/preview_image/5vvSX28mNUEc/aba9fa7a-91e6-4e4d-8f66-8379e3fc8fda.png"></el-empty>                                
                            </div>                              
                            <div class="col-sm-6 col-lg-4 col-xl-6" 
                                v-for="item in this.ModsSelect.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)" 
                                :key="item.id">
                                <div class="post-item">
                                    <div class="thumb">
                                        <router-link :to="{path:'/ModList',query: {id:item.id}}">
                                        <a href="">
                                            <img :src="item.pImage" alt="Image" style="width:100%;height:100%" v-if="item.pImage">
                                            <img src="../../assets/img/nopic.png" style="width:100%;height:100%" v-if="!item.pImage">
                                        </a>
                                        </router-link>
                                    </div>
                                    <div class="content">
                                        <div class="author">By
                                        <router-link :to="{path:'/ModsOf',query: {key:'word'}}"><a href="" v-if="item.assets==1">Word模板</a></router-link>               
                                        <router-link :to="{path:'/ModsOf',query: {key:'excel'}}"><a href="" v-if="item.assets==2">Excel模板</a></router-link>  
                                        <router-link :to="{path:'/ModsOf',query: {key:'ppt'}}"><a href="" v-if="item.assets==3">PPT模板</a></router-link>  
                                        <router-link :to="{path:'/ModsOf',query: {key:'pic'}}"><a href="" v-if="item.assets==4">广告设计素材专区</a></router-link>  
                                        <router-link :to="{path:'/ModsOf',query: {key:'#'}}"><a href="" v-if="item.assets==5">资源分享区</a></router-link>  
                                        </div>
                                        <h4 class="title"><router-link :to="{path:'/ModList',query: {id:item.id}}"><a href="">{{item.name}}</a></router-link></h4>
                                        <p>{{item.eintro}}</p>
                                        <div class="meta">
                                            <span class="post-date">{{item.upload.slice(0,10)}}</span>
                                            <span class="dots"></span>
                                            <template v-if="item.upload" >
                                            <span class="post-time" >{{dateTimes}}</span>
                                            </template>
                                        </div>
                                    </div>
                                </div>                          
                            </div>
                            <el-pagination
                                @current-change="handleCurrentChange"
                                background
                                layout="prev, pager, next, jumper"
                                :current-page="currentPage"
                                :page-size="pageSize"
                                :total="ModsSelect.length" style="text-align: center;">
                            </el-pagination>                         
                        </div>
                    </div>
                    <!-- 左侧Start-->
                    <div class="col-xl-4">
                        <div class="blog-sidebar blog-sidebar-left">
                            <!--左侧搜索框Start-->
                            <LeftSearch></LeftSearch>
                            <!--左侧搜索框End-->

                            <!--热门分类Start-->
                            <div class="widget-item">
                                <div class="widget-title">
                                <h3 class="title">热门分类</h3>
                                </div>
                                <!--热门分类组件-->
                                <PopCate></PopCate>
                            </div>
                            <!--热门分类End-->

                            <!--内容推荐Start-->
                            <div class="widget-item">
                                <div class="widget-title">
                                <h3 class="title">内容推荐</h3>
                                </div>
                                <!--内容推荐组件-->
                                <PopRecommend></PopRecommend>
                            </div>
                            <!--内容推荐End-->

                            <!--热点关键词Start-->
                            <div class="widget-item mb-md-0">
                                <div class="widget-title">
                                    <h3 class="title">热点关键词</h3>
                                </div>
                                <!--热点关键词组件-->
                                <PopKeyWords></PopKeyWords>
                            </div>
                            <!--热点关键词End-->
                        </div>
                    </div>
                    <!--左侧End-->
                </div>
            </div>
            </section>
            <!--== End==-->
        </main>    
        </div>
    </div>
</template>

<script>
import {selectByNameOrLaber} from '../../api/index'
import LeftSearch from '../mods/components/LeftSearch.vue'
import PopCate from '../mods/components/PopCate.vue'
import PopRecommend from '../mods/components/PopRecommend.vue'
import PopKeyWords from '../mods/components/PopKeyWords.vue'
export default {
    data(){
        return{
            htmluoload:"",
            Byname:'',
            ModsSelect:[],
            dateTimes:'三天前',
            pageSize: 6, // 一页显示多少条数据
            currentPage: 1, // 当前页
        }
    },
    components:{
        LeftSearch,
        PopCate,
        PopRecommend,
        PopKeyWords
    },
    created(){
        this.Byname=this.$route.query.name;
        this.ModsByName()
    },
    methods:{
        // 获取当前页
        handleCurrentChange (val) {
        this.currentPage = val
        },

        handleCount(row) {
            console.log(row);  //获取该行id
            },
        async ModsByName(){
            this.ModsSelect = []
            await selectByNameOrLaber(this.Byname).then(res => {
                console.log('模糊查询的模板：',res);
                this.ModsSelect=res
                this.currentPage = 1
            })
            
            
        },
        goModList(id){
            this.$router.push({path: `/ModList`, query: {id:id}})
        },
        newUploads(upload){
            let d = upload
            let uploads = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
            console.log(uploads);
            this.htmluoload=uploads
        },
        newDate(prevs){
            console.log("进来了");
            console.log(prevs);

        }
    }
    
}
</script>

<style lang="less" scoped>
    /deep/.el-pagination.is-background .el-pager li:not(.disabled) {
       color: #999;
      border-radius: 5px;
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
 /deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
      background-color: #03A84E;
      color: #fff;//修改选中后的样式
    }
//上一页和下一页的样式
    /deep/.el-pagination.is-background .btn-next{
      //margin: 0 5px;
      background-color: #03A84E;
      color: #fff;
      min-width: 60px;
      height: 60px;
      border-radius: 5px;
    }
    /deep/.el-pagination.is-background .btn-prev{
      //margin: 0 5px;
     background-color: #03A84E;
      color: #fff;
      min-width: 60px;
      height: 60px;
      border-radius: 5px;
    }
   /deep/.el-pagination.is-background .el-pagination__jump{

      height: 60px;
      line-height: 60px;
   }
   .Bytitles{
    padding: 24px 30px 24px;
   }
   .BytitlesBackground{
    background-color: #f4f7f7;
    border-radius: 5px;
    padding: 24px 30px 24px;
   }
</style>