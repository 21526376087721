<template>
    <div>
        <!--热门推荐组件Start-->
        <div class="widget-body">
            <div class="widget-post">
                <div>
                <div class="widget-blog-post">
                    <div class="thumb">
                        <a href="#"><img src="static/picture/s1.webp" alt="Image" width="71" height="70"></a>
                    </div>
                    <div class="content">
                        <h4><a href="#">更多资源敬请期待 <br>Please look forward to more resources.</a></h4>
                        <div class="meta">
                        <span class="post-date"><i class="icofont-ui-calendar"></i> 2023-01-01</span>
                        </div>
                    </div>
                </div>
               </div> 
                
            </div>
             
        </div>
        
        <!--热门推荐类组件End-->
    </div>
</template>
<script>
import {getAllModes} from '../../../api/index'
export default {
    data(){
        return{
            modsSelect:[],
            newMods:[]
        }
    },
    created(){
        this.getMods()
    },
    methods:{
        async getMods(){
            this.ModsSelect = []
            await getAllModes().then(res => {
                this.ModsSelect=res
                
            })                       
        },
        
    },
    mounted() {
        
    }

}
</script>